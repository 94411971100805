<template>
  <section>
    <Navbar theme="mains" />
    <main class="main">
      <router-view></router-view>
    </main>
    <notifications position="top right" />
  </section>
</template>

<script>
import Navbar from '../components/main/Navbar';
import '/public/bootstrap.css';

export default {
  name: "terminal",
  components: {
    Navbar
  }
}
</script>

<style lang="scss" scoped>

body, nav.Navbar, main {
  background: #0D151D;
  color: white;

  ::-webkit-scrollbar {
     width: 5px;
   }

  ::-webkit-scrollbar-track {
     background-color: #131E2A;
   }

  ::-webkit-scrollbar-thumb {
     background: #2E4865;
   }
}

main {
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 60px);
}

</style>